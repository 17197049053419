// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import MenuBar from './components/MenuBar';

// Import your admin components
import AdminDashboard from './admin/AdminDashboard';
import ManageUsers from './admin/ManageUsers';
import AssignAccessLevel from './admin/AssignAccessLevel';

// Import new pages
import Projects from './pages/ProjectsPage';
import EnvironmentSelectionPage from './pages/EnvironmentSelectionPage';
import SolveBendel1Page from './pages/SolveBendel1Page';
import SolveBendel2Page from './pages/SolveBendel2Page';
import TransportFluxPage from './pages/TransportFluxPage';

// Existing imports
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import NodeEditorPage from './pages/NodeEditorPage';
import SolveWeibullPage from './pages/SolveWeibullPage';

function App() {
  return (
    <UserProvider>
      <Router>
        <MenuBar />
        <Routes>
          {/* admin Routes */}
          <Route path="/admin" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>}>
            <Route path="manage-users" element={<ManageUsers />} />
            <Route path="assign-access-level" element={<AssignAccessLevel />} />
          </Route>

          {/* Public Routes */}
          <Route path="/login" element={<PublicRoute><LoginPage /></PublicRoute>} />
          <Route path="/register" element={<PublicRoute><RegisterPage /></PublicRoute>} />

          {/* Protected Routes */}
          <Route path="/projects" element={<ProtectedRoute><Projects /></ProtectedRoute>} />
          <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/environment-selection" element={<ProtectedRoute><EnvironmentSelectionPage /></ProtectedRoute>} />
          <Route path="/node-editor" element={<ProtectedRoute><NodeEditorPage /></ProtectedRoute>} />
          <Route path="/solve-weibull" element={<ProtectedRoute><SolveWeibullPage /></ProtectedRoute>} />
          <Route path="/solve-bendel1" element={<ProtectedRoute><SolveBendel1Page /></ProtectedRoute>} />
          <Route path="/solve-bendel2" element={<ProtectedRoute><SolveBendel2Page /></ProtectedRoute>} />
          <Route path="/transport-flux" element={<ProtectedRoute><TransportFluxPage /></ProtectedRoute>} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;