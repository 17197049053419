// src/pages/EnvironmentSelectionPage.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { UserContext } from '../contexts/UserContext';
import EnvironmentPlot from '../components/EnvironmentPlot'; // Import the component

const databaseUrl = process.env.REACT_APP_FLASK_URL;

const EnvironmentSelectionPage = () => {
  const [availableEnvironments, setAvailableEnvironments] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [environmentData, setEnvironmentData] = useState(null);
  const [error, setError] = useState(null);
  const { setEnvironment } = useContext(UserContext); // Get setEnvironment from context

  useEffect(() => {
    const fetchAvailableEnvironments = async () => {
      try {
        const response = await axios.get(`${databaseUrl}/environment/list_proton_environments`);
        setAvailableEnvironments(response.data.proton_environments);
      } catch (err) {
        setError('Failed to load available environments.');
        console.error('Error loading available environments:', err);
      }
    };

    fetchAvailableEnvironments();
  }, []);

  const handleEnvironmentSelect = async (e) => {
    const filename = e.target.value;
    setSelectedEnvironment(filename);

    try {
      const response = await axios.post(`${databaseUrl}/environment/load_environment`, {
        filename: filename,
      });
      const data = response.data.data;
      setEnvironmentData(data);

      // Store selected environment in context
      setEnvironment(data);

    } catch (err) {
      setError('Failed to load environment data.');
      console.error('Error loading environment:', err);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Select a Proton Environment</h2>
      {availableEnvironments.length > 0 ? (
        <select value={selectedEnvironment} onChange={handleEnvironmentSelect}>
          <option value="" disabled>
            Select an environment
          </option>
          {availableEnvironments.map((filename, index) => (
            <option key={index} value={filename}>
              {filename}
            </option>
          ))}
        </select>
      ) : (
        <div>Loading available environments...</div>
      )}

      {environmentData && (
        <div>
          <h2>Environment Data</h2>
          {/* Removed mission duration display */}
          {/* Plotting the data */}
          <EnvironmentPlot
            energies={environmentData.energies}
            fluences={environmentData.fluences}
          />
        </div>
      )}
    </div>
  );
};

export default EnvironmentSelectionPage;