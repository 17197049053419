// src/pages/TransportFluxPage.js
import React from 'react';
import TransportFlux from '../components/solve_functions/TransportFlux';

function TransportFluxPage() {
  return (
    <div>
      <h1>Transport Flux</h1>
      <TransportFlux />
    </div>
  );
}

export default TransportFluxPage;