//src/components/solve_functions/SolveWeibull.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SolveWeibull = () => {
    const [LETValues, setLETValues] = useState('');
    const [countsValues, setCountsValues] = useState('');
    const [fluenceValues, setFluenceValues] = useState('');
    const [weibullParams, setWeibullParams] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [plotData, setPlotData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    // Function to save form data to sessionStorage
    const saveFormData = (data) => {
        sessionStorage.setItem('weibullFormData', JSON.stringify(data));
    };

    // Function to restore form data from sessionStorage
    const restoreFormData = () => {
        const savedFormData = JSON.parse(sessionStorage.getItem('weibullFormData'));
        if (savedFormData) {
            setLETValues(savedFormData.LETValues);
            setCountsValues(savedFormData.countsValues);
            setFluenceValues(savedFormData.fluenceValues);
        }
    };

    // Use useEffect to restore form data when the component mounts
    useEffect(() => {
        restoreFormData();
    }, []);

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Start loading spinner
        setErrorMessage(''); // Reset error message

        // Split the input values by newlines or commas and filter out empty strings
        const LETArray = LETValues.split(/[\n,]+/).map(val => val.trim()).filter(val => val !== '');
        const countsArray = countsValues.split(/[\n,]+/).map(val => val.trim()).filter(val => val !== '');
        const fluenceArray = fluenceValues.split(/[\n,]+/).map(val => val.trim()).filter(val => val !== '');

        // Check if all arrays have the same length
        if (LETArray.length !== countsArray.length || countsArray.length !== fluenceArray.length) {
            setErrorMessage('Error: LET, counts, and fluence must have the same number of entries.');
            setIsLoading(false); // Stop loading spinner
            return;
        }

        // Save form data to sessionStorage
        saveFormData({ LETValues, countsValues, fluenceValues });

        // Convert the data to the correct format
        const formattedDataRows = LETArray.map((letValue, index) => ({
            LET: parseFloat(letValue),
            counts: parseFloat(countsArray[index]),
            fluence: parseFloat(fluenceArray[index])
        }));

        try {
            const response = await axios.post(`${backendUrl}/solve`, formattedDataRows);
            setWeibullParams(response.data);
            setIsLoading(false); // Stop loading spinner

            // Generate plot data
            const xs = Array.from({ length: 121 }, (_, i) => i); // Generate x values from 0 to 120
            const ys = xs.map((x) =>
                weibull(x, response.data.a, response.data.x0, response.data.w, response.data.s)
            );

            // Test points
            const testPointsX = formattedDataRows.map(row => row.LET);
            const testPointsY = formattedDataRows.map(row => row.counts / row.fluence);

            setPlotData([
                {
                    x: xs,
                    y: ys,
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: 'red' },
                    name: 'Weibull Fit',
                },
                {
                    x: testPointsX,
                    y: testPointsY,
                    type: 'scatter',
                    mode: 'markers',
                    marker: { color: 'blue', size: 8 },
                    name: 'Test Points',
                }
            ]);
        } catch (error) {
            console.error('Error in solving Weibull parameters:', error);
            setIsLoading(false); // Stop loading spinner
            setErrorMessage('An error occurred while computing Weibull parameters.');
        }
    };

    const weibull = (x, a, x0, w, s) => {
        if (x <= x0) return 0;
        return a * (1 - Math.exp(-Math.pow((x - x0) / w, s)));
    };

    return (
        <div>
            <h2>Input Test Data</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <thead>
                        <tr>
                            <th>LET</th>
                            <th>Counts</th>
                            <th>Fluence</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <textarea
                                    name="LETValues"
                                    value={LETValues}
                                    onChange={(e) => setLETValues(e.target.value)}
                                    rows="10"
                                    cols="20"
                                    placeholder="Paste LET values here"
                                ></textarea>
                            </td>
                            <td>
                                <textarea
                                    name="countsValues"
                                    value={countsValues}
                                    onChange={(e) => setCountsValues(e.target.value)}
                                    rows="10"
                                    cols="20"
                                    placeholder="Paste counts values here"
                                ></textarea>
                            </td>
                            <td>
                                <textarea
                                    name="fluenceValues"
                                    value={fluenceValues}
                                    onChange={(e) => setFluenceValues(e.target.value)}
                                    rows="10"
                                    cols="20"
                                    placeholder="Paste fluence values here"
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="submit">Compute Weibull Parameters</button>
            </form>

            {isLoading && (
                <div>Loading...</div> // Display the loading spinner
            )}

            {errorMessage && (
                <div style={{ color: 'red' }}>{errorMessage}</div>
            )}

            {weibullParams && (
                <div>
                    <h3>Computed Weibull Parameters:</h3>
                    <p>Saturation (A): {weibullParams.a.toExponential(2)}</p>
                    <p>Onset (x0): {weibullParams.x0.toPrecision(2)}</p>
                    <p>Width (w): {weibullParams.w.toFixed(2)}</p>
                    <p>Shape (s): {weibullParams.s.toPrecision(2)}</p>
                </div>
            )}

            {plotData && (
                <div>
                    <h2>Weibull Function Plot</h2>
                    <Plot
                        data={plotData}
                        layout={{
                            title: 'Weibull Fit for Radiation Test Data',
                            xaxis: { title: 'LET (MeV-cm²/mg)' },
                            yaxis: { title: 'Cross Section (cm²)', type: 'log' }, // Set y-axis to log scale
                            autosize: true
                        }}
                        style={{ width: "100%", height: "500px" }}
                    />
                </div>
            )}
        </div>
    );
};

export default SolveWeibull;