// NodeEditor.js

import React, { useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer';

import NodePopupMenu from '../menu/NodePopupMenu';
import { saveDiagramAsHTML, exportAsJson } from './DiagramIO';
import DefaultNode from './nodes/DefaultNode';
import OrNode from './nodes/OrNode';
import AndNode from './nodes/AndNode';
import NoomNode from './nodes/NoomNode';
import LogicNode from './nodes/LogicNode';
import SubsystemNode from './nodes/SubsystemNode';
import ScheduleNode from './nodes/ScheduleNode';
import { addNode } from './node_actions/AddNode';
import { setUpstreamDownstream } from './node_actions/EditNode';

import { resetNodesAndEdges } from '../utils/nodeActionsUtils';
import { cacheCurrentDiagram } from './node_actions/CacheActions';

// Import Tree component
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';

// Import FontAwesome for icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile } from '@fortawesome/free-solid-svg-icons';

const gridSize = 20;

const nodeTypes = {
  default: DefaultNode,
  orNode: OrNode,
  andNode: AndNode,
  noomNode: NoomNode,
  logicNode: LogicNode,
  scheduleNode: ScheduleNode,
  subsystemNode: SubsystemNode,
};

const NodeEditor = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeId, setNodeId] = useState(1);
  const [currentNode, setCurrentNode] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [isSimplePopupOpen, setSimplePopupOpen] = useState(false);
  const [cachedDiagrams, setCachedDiagrams] = useState({});
  const [viewStack, setViewStack] = useState([]);

  const [treeData, setTreeData] = useState([
    {
      title: 'System',
      key: '0',
      children: [
        {
          title: 'System 1',
          key: '0-0',
          children: [{ title: 'SubSystem 1', key: '0-0-0' }],
        },
        {
          title: 'System 2',
          key: '0-1',
          children: [{ title: 'SubSystem 2', key: '0-1-0' }],
        },
      ],
    },
  ]);

  const [selectedFolder, setSelectedFolder] = useState(null);

  // Handle view selection from the folder tree
  const handleViewSelect = (viewName) => {
    if (viewName === currentView) return;

    cacheCurrentDiagram(currentView, nodes, edges, setCachedDiagrams);

    const cachedDiagram = cachedDiagrams[viewName];
    if (cachedDiagram) {
      setNodes(cachedDiagram.nodes);
      setEdges(cachedDiagram.edges);
    } else {
      resetNodesAndEdges(setNodes, setEdges, setNodeId);
    }

    setCurrentView(viewName);
  };

  // Handle node selection
  const onSelect = (selectedKeys, info) => {
    const selectedNode = info.node;
    handleViewSelect(selectedNode.title);
    setSelectedFolder(selectedNode);
  };

  // Handle drag enter
  const onDragEnter = (info) => {
    // Can expand the tree node when drag enter
    // console.log(info);
  };

  // Handle drop
  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...treeData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // Insert at the end
        item.children.push(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeData(data);
  };

  // Function to add a folder
  const handleAddFolder = () => {
    if (selectedFolder) {
      const newKey = `${selectedFolder.key}-${Date.now()}`;
      selectedFolder.children = selectedFolder.children || [];
      selectedFolder.children.push({
        title: `New Folder`,
        key: newKey,
        children: [],
      });
      setTreeData([...treeData]);
    }
  };

  // Function to add a view (block structure)
  const handleAddView = () => {
    if (selectedFolder) {
      const newKey = `${selectedFolder.key}-${Date.now()}`;
      selectedFolder.children = selectedFolder.children || [];
      selectedFolder.children.push({
        title: `New View`,
        key: newKey,
      });
      setTreeData([...treeData]);
    }
  };

  // Function to handle delete action
  const handleDelete = () => {
    if (!selectedFolder) return;

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data, i);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...treeData];

    loop(data, selectedFolder.key, (arr, index) => {
      arr.splice(index, 1);
    });

    setTreeData(data);
    setSelectedFolder(null);
  };

  const handleNodeDoubleClick = (event, node) => {
    event.preventDefault();

    if (node.type === 'subsystemNode') {
      const subsystemKey = node.data.subsystemKey;
      const cachedDiagram = cachedDiagrams[subsystemKey];
      if (cachedDiagram) {
        // Save the current view to the stack before navigating
        setViewStack((prevStack) => [...prevStack, { nodes, edges, viewName: currentView }]);

        // Load the subsystem's nodes and edges
        setNodes(cachedDiagram.nodes);
        setEdges(cachedDiagram.edges);
        setCurrentView(subsystemKey);
      }
    } else {
      setCurrentNode(node);
      setSimplePopupOpen(true);
    }
  };

  const handleNodeAdd = (type, label) => {
    addNode(type, label, nodes, setNodes, nodeId, setNodeId);
  };

  const handleReset = () => {
    resetNodesAndEdges(setNodes, setEdges, setNodeId);
  };

  const handleEdgeConnect = (params) => {
    setEdges((eds) => {
      const newEdges = addEdge(params, eds);
      setUpstreamDownstream(params.source, params.target, nodes, setNodes);
      return newEdges;
    });
  };

  const handleBack = () => {
    if (viewStack.length > 0) {
      const previousView = viewStack[viewStack.length - 1];
      setNodes(previousView.nodes);
      setEdges(previousView.edges);
      setCurrentView(previousView.viewName);
      setViewStack((prevStack) => prevStack.slice(0, -1));
    }
  };

  const handleCreateSubsystemBlock = () => {
    const subsystemName = prompt('Enter a name for the subsystem:');

    if (!subsystemName || subsystemName.trim() === '') {
      alert('Subsystem creation cancelled or invalid name.');
      return;
    }

    // Save current nodes and edges to cachedDiagrams
    setCachedDiagrams((prevCache) => ({
      ...prevCache,
      [subsystemName]: { nodes, edges },
    }));

    // Create a subsystem node
    const subsystemNode = {
      id: `node-${nodeId}`,
      type: 'subsystemNode',
      data: {
        label: subsystemName,
        subsystemKey: subsystemName,
        upstream: [],
        downstream: [],
      },
      position: {
        x: window.innerWidth * 0.8 - 250,
        y: window.innerHeight * 0.1,
      },
    };
    setNodeId((prevId) => prevId + 1);

    // Replace current nodes and edges with the subsystem node
    setNodes([subsystemNode]);
    setEdges([]);

    // Add the subsystem to the tree
    if (selectedFolder) {
      const newKey = `${selectedFolder.key}-${Date.now()}`;
      selectedFolder.children = selectedFolder.children || [];
      selectedFolder.children.push({ title: subsystemName, key: newKey });
      setTreeData([...treeData]);
    }
  };

  // Custom rendering of tree nodes with icons
  const titleRender = (nodeData) => (
    <span>
      <FontAwesomeIcon
        icon={nodeData.children ? faFolder : faFile}
        style={{ marginRight: '5px' }}
      />
      {nodeData.title}
    </span>
  );

  return (
    <div id="node-editor" style={{ display: 'flex', height: '100vh' }}>
      {/* Side Menu */}
      <div
        style={{
          width: '300px',
          borderRight: '1px solid #ccc',
          padding: '10px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <button onClick={handleAddFolder} style={{ marginBottom: '10px', width: '100%' }}>
          Add Folder
        </button>
        <button onClick={handleAddView} style={{ marginBottom: '10px', width: '100%' }}>
          Add View to {selectedFolder?.title || 'Folder'}
        </button>
        <button onClick={handleDelete} style={{ marginBottom: '10px', width: '100%' }}>
          Delete {selectedFolder?.title}
        </button>
        <button onClick={handleCreateSubsystemBlock} style={{ marginBottom: '10px', width: '100%' }}>
          Create Subsystem Block
        </button>
        <button onClick={handleBack} style={{ marginBottom: '10px', width: '100%' }}>
          Back
        </button>
        <div style={{ height: 'calc(100% - 250px)', overflowY: 'auto' }}>
          <Tree
            treeData={treeData}
            draggable
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onSelect={onSelect}
            defaultExpandAll
            titleRender={titleRender}
          />
        </div>
      </div>
      <div style={{ flexGrow: 1, padding: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <button
            onClick={() => {
              handleNodeAdd('default', `Node ${nodeId}`);
            }}
            style={{ marginRight: '10px' }}
          >
            Add Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('orNode', 'OR');
            }}
            style={{ marginRight: '10px' }}
          >
            Add OR Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('andNode', 'AND');
            }}
            style={{ marginRight: '10px' }}
          >
            Add AND Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('noomNode', 'NooM');
            }}
            style={{ marginRight: '10px' }}
          >
            Add NooM Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('logicNode', 'Logic');
            }}
            style={{ marginRight: '10px' }}
          >
            Add Logic Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('scheduleNode', 'Schedule');
            }}
            style={{ marginRight: '10px' }}
          >
            Add Schedule Node
          </button>
          <button onClick={handleReset} style={{ marginRight: '10px' }}>
            Reset
          </button>
          <button onClick={() => saveDiagramAsHTML(nodes, edges)} style={{ marginRight: '10px' }}>
            Save Diagram as HTML
          </button>
          <button onClick={() => exportAsJson(nodes, edges)} style={{ marginRight: '10px' }}>
            Export as JSON
          </button>
        </div>
        <div style={{ width: '100%', height: 'calc(100% - 40px)', position: 'relative' }}>
          <ReactFlowProvider>
            <div style={{ width: '100%', height: '100%' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={handleEdgeConnect}
                nodeTypes={nodeTypes}
                fitView
                onNodeDoubleClick={handleNodeDoubleClick}
                snapToGrid={true}
                snapGrid={[gridSize, gridSize]}
                connectionMode="loose"
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
          <NodePopupMenu open={isSimplePopupOpen} handleClose={() => setSimplePopupOpen(false)} />
        </div>
      </div>
    </div>
  );
};

export default NodeEditor;