import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';  // Import the UserContext

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const CreateProjectForm = () => {
  const { user } = useContext(UserContext);  // Get the user from UserContext
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Assume you have the JWT token and user ID stored in user context
      const token = user.token;
      const user_id = user.id;  // Extract user_id from the user context
      console.log('User ID:', user_id);
      console.log('Token:', token);

      const response = await axios.post(`${databaseUrl}/create_project`,  // Use backticks here
        {
          name,
          description,
          user_id,  // Pass user_id along with project name and description
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Attach the token here
          },
        }
      );
      alert('Project created successfully');
      console.log(response.data);  // Log or handle the response data from the server
    } catch (error) {
      console.error('There was an error creating the project!', error);
      alert('Failed to create project');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Project Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Project Name"
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the project"
          required
        />
      </div>
      <button type="submit">Create Project</button>
    </form>
  );
};

export default CreateProjectForm;