// src/pages/SolveBendel2Page.js
import React from 'react';
import SolveBendel2 from '../components/solve_functions/SolveBendel2';

function SolveBendel2Page() {
  return (
    <div>
      <h1>Solve Bendel 2</h1>
      <SolveBendel2 />
    </div>
  );
}

export default SolveBendel2Page;