// src/components/EnvironmentPlot.js

import React from 'react';
import Plot from 'react-plotly.js';

const EnvironmentPlot = ({ energies, fluences }) => {
  // Prepare data for plotting
  const data = [
    {
      x: energies,
      y: fluences,
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: 'blue' },
    },
  ];

  // Plot layout
  const layout = {
    title: 'Fluence vs. Energy',
    xaxis: {
      title: 'Energy (MeV)',
      type: 'log',
    },
    yaxis: {
      title: 'Fluence (#/cm²/MeV)',
      type: 'log',
    },
    autosize: true,
  };

  return (
    <div>
      <h3>Fluence vs. Energy Plot</h3>
      <Plot
        data={data}
        layout={layout}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default EnvironmentPlot;