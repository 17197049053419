// src/components/solve_functions/TransportFlux.js

import React, { useContext, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import Plot from 'react-plotly.js';

const TransportFlux = () => {
  const { environment, transportResult, setTransportResult } = useContext(UserContext);
  const [thickness, setThickness] = useState(''); // Thickness input
  const [error, setError] = useState('');

  const transportUrl = process.env.REACT_APP_TRANSPORT_URL; // e.g., http://localhost:8082

  // Ensure that environment data is available
  if (!environment) {
    return <div>No environment selected. Please select an environment first.</div>;
  }
  console.log('Transport URL:', transportUrl);

  const { energies, fluences } = environment;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare data to send to the transport endpoint
      const payload = {
        spectrum: {
          energies: energies,
          fluxes: fluences,
        },
        thicknesses: [parseFloat(thickness)],
      };

      // Log the URL and payload for debugging
      console.log('Transport URL:', `${transportUrl}/transport`);
      console.log('Payload:', payload);

      // Make sure to use axios.post
      const response = await axios.post(`${transportUrl}/transport`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Store the transport result in UserContext
      setTransportResult(response.data);
      setError('');
    } catch (err) {
      if (err.response) {
        console.error('Server responded with an error:', err.response.status);
        console.error('Response data:', err.response.data);
        setError(`Error transporting flux: ${err.response.status} - ${err.response.data.message || err.response.data}`);
      } else if (err.request) {
        console.error('No response received:', err.request);
        setError('Error transporting flux: No response received from server');
      } else {
        console.error('Error setting up request:', err.message);
        setError(`Error transporting flux: ${err.message}`);
      }
      console.error('Axios error:', err);
    }
  };

  // Plotting function
  const plotTransportedFlux = () => {
    const originalFluxTrace = {
      x: energies,
      y: fluences,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Original Flux',
      line: { color: 'blue' },
    };

    const transportedFluxTrace = {
      x: transportResult.energies,
      y: transportResult.fluxes,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Transported Flux',
      line: { color: 'red' },
    };

    const data = [originalFluxTrace, transportedFluxTrace];

    const layout = {
      title: 'Flux vs. Energy',
      xaxis: {
        title: 'Energy (MeV)',
        type: 'log',
      },
      yaxis: {
        title: 'Flux (#/cm²/MeV)',
        type: 'log',
      },
    };

    return (
      <Plot
        data={data}
        layout={layout}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    );
  };

  return (
    <div>
      <h2>Transport Flux</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Thickness (g/cm²):
          <input
            type="number"
            value={thickness}
            onChange={(e) => setThickness(e.target.value)}
            required
            step="0.01"
            min="0"
          />
        </label>
        <br />
        <button type="submit">Transport Flux</button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {transportResult && (
        <div>
          <h3>Transported Flux Result:</h3>
          {plotTransportedFlux()}
        </div>
      )}
    </div>
  );
};

export default TransportFlux;