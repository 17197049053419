// src/pages/ProjectsPage.js

import React from 'react';
import ViewProjects from '../components/view_nodes/ViewProjects';
import CreateProjectForm from '../connections/CreateProjectForm';

const ProjectsPage = () => {
  return (
    <div>
      <h1>Projects</h1>
      {/* Create Project Form */}
      <CreateProjectForm />
      {/* View Projects */}
      <ViewProjects />
    </div>
  );
};

export default ProjectsPage;