// src/admin/AssignAccessLevel.js
import React, { useState } from 'react';
import axios from 'axios';

const AssignAccessLevel = () => {
  const [username, setUsername] = useState('');
  const [accessLevel, setAccessLevel] = useState('free-tier');
  const backendUrl = process.env.REACT_APP_FLASK_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${backendUrl}/admin/assign_access_level`, {
      username,
      access_level: accessLevel,
    })
      .then(() => alert('Access level updated successfully'))
      .catch(() => alert('Failed to update access level'));
  };

  return (
    <div>
      <h2>Assign Access Level</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input value={username} onChange={(e) => setUsername(e.target.value)} required />
        </label>
        <label>
          Access Level:
          <select value={accessLevel} onChange={(e) => setAccessLevel(e.target.value)}>
            <option value="free-tier">Free Tier</option>
            <option value="elevated-tier">Elevated Tier</option>
          </select>
        </label>
        <button type="submit">Assign</button>
      </form>
    </div>
  );
};

export default AssignAccessLevel;