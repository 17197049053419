// src/components/MenuBar.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import LogoutForm from './forms/LogoutForm';
import './MenuBar.css'; // Import the CSS file

const MenuBar = () => {
  const { user } = useContext(UserContext);
  if (user) {
    console.log('User in MenuBar:', user); // Should now log the complete user object
  }

  return (
    <nav className="menu-bar">
      <div className="menu-links">
        <Link to="/">Home</Link>
        {/* New link to Projects Page */}
        {user && (
          <Link to="/projects">Projects</Link>
        )}

        <div className="dropdown">
          <button className="dropbtn">Solve SEE Problems</button>
          <div className="dropdown-content">
            <Link to="/environment-selection">Environment Selection</Link>
            <Link to="/solve-weibull">Solve Weibull</Link>
            <Link to="/solve-bendel1">Solve Bendel 1</Link>
            <Link to="/solve-bendel2">Solve Bendel 2</Link>
            <Link to="/transport-flux">Transport Flux</Link>
          </div>
        </div>
        <Link to="/node-editor">Node Editor</Link>
        {/* Show Admin Dashboard link if the user is an admin */}
        {user && user.role === 'admin' && (
          <Link to="/admin">Admin Dashboard</Link>
        )}
      </div>
      <div className="menu-auth">
        {user ? (
          <>
            <span>Welcome, {user.username}</span>
            <LogoutForm />
          </>
        ) : (
          <>
            <Link to="/login">Login</Link>
            <Link to="/register">Register</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default MenuBar;