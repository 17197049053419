// src/pages/HomePage.js

import React from 'react';

function HomePage() {
  return (
    <div className="main-content">
      <h1>Welcome to the Main App</h1>
      <p>This is the index page with several areas for text and pictures.</p>
      {/* Your additional content here */}
    </div>
  );
}

export default HomePage;