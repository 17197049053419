// src/admin/AdminDashboard.js
import React from 'react';
import AdminMenu from './AdminMenu';
import { Outlet } from 'react-router-dom';

const AdminDashboard = () => (
  <div>
    <h1>Admin Dashboard</h1>
    <AdminMenu />
    <Outlet />
  </div>
);

const dashboardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
};

const contentStyle = {
    marginTop: '20px',
    width: '100%',
};

export default AdminDashboard;