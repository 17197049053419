import React from 'react';
import { Link } from 'react-router-dom';

const AdminMenu = () => {
    return (
        <nav style={menuStyle}>
            <Link to="/admin/manage-users" style={linkStyle}>Manage Users</Link>
            <Link to="/admin/assign-access-level" style={linkStyle}>Assign Access Level</Link>
        </nav>
    );
};

const menuStyle = {
    display: 'flex',
    gap: '20px',
};

const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    background: '#007BFF',
    padding: '10px 20px',
    borderRadius: '5px',
};

export default AdminMenu;