// src/admin/ManageUsers.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const backendUrl = process.env.REACT_APP_FLASK_URL;

  useEffect(() => {
    axios.get(`${backendUrl}/admin/users`)
      .then((response) => setUsers(response.data))
      .catch((error) => console.error('Error fetching users:', error));
  }, [backendUrl]);

  const deleteUser = (userId) => {
    axios.delete(`${backendUrl}/admin/manage-users/${userId}`)
      .then(() => setUsers(users.filter((user) => user.id !== userId)))
      .catch((error) => console.error('Error deleting user:', error));
  };

  return (
    <div>
      <h2>Manage Users</h2>
      <table>
        {/* Table headers */}
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              {/* User details */}
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <button onClick={() => deleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUsers;