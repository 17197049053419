import React from 'react';
import SolveWeibull from '../components/solve_functions/SolveWeibull';

function SolveWeibullPage() {
  return (
    <div>
      <h1>Solve Weibull Curves</h1>
      <SolveWeibull />
    </div>
  );
}

export default SolveWeibullPage;