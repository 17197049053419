export const renameNode = (id, newName, nodes, setNodes) => {
    setNodes(nodes.map(node => 
      node.id === id ? { ...node, data: { ...node.data, label: newName } } : node
    ));
  };
  
  export const setUpstreamDownstream = (sourceId, targetId, nodes, setNodes) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === sourceId) {
          return {
            ...node,
            data: {
              ...node.data,
              downstream: [...(node.data.downstream || []), targetId],
            },
          };
        } else if (node.id === targetId) {
          return {
            ...node,
            data: {
              ...node.data,
              upstream: [...(node.data.upstream || []), sourceId],
            },
          };
        }
        return node;
      })
    );
  };