//node_editor/CacheActions.js
export const cacheCurrentDiagram = (currentView, nodes, edges, setCachedDiagrams) => {
    if (currentView) {
      setCachedDiagrams((prevCache) => ({
        ...prevCache,
        [currentView]: { nodes, edges },
      }));
    }
  };
  
  export const loadCachedDiagram = (viewName, cachedDiagrams, setNodes, setEdges, resetNodesAndEdges, setNodeId) => {
    const cachedDiagram = cachedDiagrams[viewName];
    if (cachedDiagram) {
      setNodes(cachedDiagram.nodes);
      setEdges(cachedDiagram.edges);
    } else {
      resetNodesAndEdges(setNodes, setEdges, setNodeId);
    }
  };