import { saveAs } from 'file-saver';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const saveDiagramAsHTML = (nodes, edges) => {
    const diagramHTML = `
      <html>
      <head>
        <title>Node Diagram</title>
      </head>
      <body>
        <pre>${JSON.stringify({ nodes, edges }, null, 2)}</pre>
      </body>
      </html>
    `;

    const blob = new Blob([diagramHTML], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'diagram.html';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const exportAsJson = (nodes, edges) => {
    const blob = new Blob([JSON.stringify({ nodes, edges }, null, 2)], { type: 'application/json;charset=utf-8' });
    saveAs(blob, 'diagram.json');
};

const loadDiagramFromHTML = (file, setNodes, setEdges) => {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            const parsedContent = JSON.parse(content.match(/<pre>(.*?)<\/pre>/s)[1]);
            setNodes(parsedContent.nodes || []);
            setEdges(parsedContent.edges || []);
        };
        reader.readAsText(file);
    }
};

export { saveDiagramAsHTML, exportAsJson, loadDiagramFromHTML };