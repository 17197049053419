import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NodePopupMenu = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);

  if (!open) return null;

  // Function to navigate to different forms
  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  const handleSecondaryMenuOpen = () => {
    setShowSecondaryMenu(true);
  };

  const handleSecondaryMenuClose = () => {
    setShowSecondaryMenu(false);
  };

  return (
    <div style={backdropStyle} onClick={handleClose}>
      <div style={popupStyle} onClick={(e) => e.stopPropagation()}>
        <h3>Assign Attributes</h3>
        <div>
          <button onClick={handleSecondaryMenuOpen}>Create Unit/Part</button>
        </div>
        <button onClick={handleClose} style={closeButtonStyle}>Close</button>

        {showSecondaryMenu && (
          <div style={secondaryPopupStyle} onClick={(e) => e.stopPropagation()}>
            <h4>Create Options</h4>
            <div>
              <button onClick={() => handleNavigation('/create-unit')}>Create Unit</button>
            </div>
            <div>
              <button onClick={() => handleNavigation('/create-part')}>Create Part</button>
            </div>
            <button onClick={handleSecondaryMenuClose} style={closeButtonStyle}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

// Inline styles for simplicity
const backdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const popupStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1001,
};

const secondaryPopupStyle = {
  backgroundColor: '#f0f0f0',
  padding: '15px',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  position: 'absolute',
  top: '60px', // Adjust position relative to the main popup
  right: '20px',
  zIndex: 1002,
};

const closeButtonStyle = {
  marginTop: '10px',
};

export default NodePopupMenu;