import React from 'react';
import { Handle } from 'react-flow-renderer';

const OrNode = ({ data, style }) => (
  <div style={{ ...style, padding: '10px', background: '#e6e6e6', borderRadius: '5px' }}>
    <Handle type="source" position="left" id="target-left" style={{ borderRadius: 0 }} />
    <Handle type="target" position="right" id="source-right" style={{ borderRadius: 0 }} />
    <div>{data.label}</div>
    <div style={{ fontSize: '12px', marginTop: '5px' }}>
      <strong>Upstream:</strong> {data.upstream.join(', ') || 'None'}
      <br />
      <strong>Downstream:</strong> {data.downstream.join(', ') || 'None'}
    </div>
  </div>
);

export default OrNode;