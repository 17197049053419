// src/components/solve_functions/SolveBendel2.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { UserContext } from '../../contexts/UserContext';

const bendelUrl2 = process.env.REACT_APP_BENDEL2_URL;

const SolveBendel2 = () => {
  const { transportResult } = useContext(UserContext);
  const [energies, setEnergies] = useState(['', '']); // Array of two energies
  const [crossSections, setCrossSections] = useState(['', '']); // Array of two cross-sections
  const [result, setResult] = useState(null);
  const [convolutionResult, setConvolutionResult] = useState(null);
  const [error, setError] = useState('');
  console.log(bendelUrl2);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setResult(null);

    // Validate inputs
    if (energies.some((e) => e === '') || crossSections.some((cs) => cs === '')) {
      setError('Please provide all energy and cross-section values.');
      return;
    }

    try {
      const response = await axios.post(`${bendelUrl2}/solve_bendel2`, {
        energies: energies.map(Number),
        cross_sections: crossSections.map(Number),
      });

      setResult(response.data);
    } catch (err) {
      setError('Error solving Bendel 2');
      console.error(err);
    }
  };

  // Function to generate plot data
  const generatePlotData = () => {
    const data = [];

    if (result) {
      const { energies: resultEnergies, cross_sections: resultCrossSections } = result;

      data.push({
        x: resultEnergies,
        y: resultCrossSections,
        type: 'scatter',
        mode: 'lines',
        marker: { color: 'green' },
        name: 'Bendel 2 Fit',
      });
    }

    if (convolutionResult) {
      const { energies, rates } = convolutionResult;

      data.push({
        x: energies,
        y: rates,
        type: 'scatter',
        mode: 'lines',
        marker: { color: 'purple' },
        name: 'Convolution (Upsets per Day)',
        yaxis: 'y2',
      });
    }

    return data;
  };

  const handleConvolution = () => {
    if (!result || !transportResult) {
      setError('Please solve Bendel 2 and transport flux first.');
      return;
    }

    const { energies: bendelEnergies, cross_sections } = result;
    const { energies: fluxEnergies, fluxes } = transportResult;

    // Interpolate cross sections to flux energies
    const interpolatedCrossSections = fluxEnergies.map((energy) => {
      return interpolate(energy, bendelEnergies, cross_sections);
    });

    // Compute energy bin widths
    const energyBins = fluxEnergies;
    const binWidths = energyBins.map((e, i) => {
      if (i === 0) {
        return energyBins[i + 1] - e;
      } else {
        return e - energyBins[i - 1];
      }
    });

    // Compute rates
    const rates = fluxEnergies.map((_, i) => {
      const rate = interpolatedCrossSections[i] * fluxes[i] * binWidths[i];
      return rate;
    });

    // Total upsets per day
    const totalUpsetsPerDay = rates.reduce((sum, r) => sum + r, 0);

    setConvolutionResult({
      energies: fluxEnergies,
      rates,
      totalUpsetsPerDay,
    });
  };

  // Helper function for interpolation
  const interpolate = (x, xArray, yArray) => {
    if (x <= xArray[0]) {
      return yArray[0];
    } else if (x >= xArray[xArray.length - 1]) {
      return yArray[yArray.length - 1];
    } else {
      let i = xArray.findIndex((val) => val > x) - 1;
      if (i < 0) i = 0;
      const x0 = xArray[i];
      const x1 = xArray[i + 1];
      const y0 = yArray[i];
      const y1 = yArray[i + 1];
      const t = (x - x0) / (x1 - x0);
      return y0 + t * (y1 - y0);
    }
  };

  return (
    <div className="main-content">
      <h2>Solve Bendel 2 Parameter Fit</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <h3>First Data Point:</h3>
          <label>
            Energy 1 (MeV):
            <input
              type="number"
              value={energies[0]}
              onChange={(e) => {
                const newEnergies = [...energies];
                newEnergies[0] = e.target.value;
                setEnergies(newEnergies);
              }}
              required
            />
          </label>
          <label>
            Cross Section 1 (cm²):
            <input
              type="number"
              value={crossSections[0]}
              onChange={(e) => {
                const newCrossSections = [...crossSections];
                newCrossSections[0] = e.target.value;
                setCrossSections(newCrossSections);
              }}
              required
            />
          </label>
        </div>
        <div>
          <h3>Second Data Point:</h3>
          <label>
            Energy 2 (MeV):
            <input
              type="number"
              value={energies[1]}
              onChange={(e) => {
                const newEnergies = [...energies];
                newEnergies[1] = e.target.value;
                setEnergies(newEnergies);
              }}
              required
            />
          </label>
          <label>
            Cross Section 2 (cm²):
            <input
              type="number"
              value={crossSections[1]}
              onChange={(e) => {
                const newCrossSections = [...crossSections];
                newCrossSections[1] = e.target.value;
                setCrossSections(newCrossSections);
              }}
              required
            />
          </label>
        </div>
        <button type="submit">Compute Bendel 2 Fit</button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {result && (
        <div>
          <h3>Result:</h3>
          <p>Fitting Parameters:</p>
          <pre>{JSON.stringify(result.parameters, null, 2)}</pre>
          <button onClick={handleConvolution}>Compute Convolution</button>

          {convolutionResult && (
            <div>
              <h3>Convolution Result:</h3>
              <p>
                Total Upsets per Day:{' '}
                {convolutionResult.totalUpsetsPerDay.toExponential(2)}
              </p>
            </div>
          )}

          <h3>Bendel 2 Curve:</h3>
          <Plot
            data={generatePlotData()}
            layout={{
              title: 'Bendel 2 Fit Curve',
              xaxis: { title: 'Energy (MeV)', type: 'log' },
              yaxis: { title: 'Cross Section (cm²)', type: 'log' },
              yaxis2: {
                title: 'Upsets per Day',
                overlaying: 'y',
                side: 'right',
                type: 'log',
              },
              autosize: true,
            }}
            style={{ width: '100%', height: '500px' }}
          />
        </div>
      )}
    </div>
  );
};

export default SolveBendel2;