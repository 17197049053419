// ./node_actions/AddNode.js
export const addNode = (type, label, nodes, setNodes, nodeId, setNodeId) => {
  const newNode = {
    id: `node-${nodeId}`,
    type: type,
    data: {
      label,
      upstream: [],
      downstream: [],
    },
    position: {
      x: window.innerWidth * 0.8 - 250,
      y: window.innerHeight * 0.1,
    },
  };
  setNodes([...nodes, newNode]);
  setNodeId((prevId) => prevId + 1);
};