// src/pages/SolveBendel1Page.js
import React from 'react';
import SolveBendel1 from '../components/solve_functions/SolveBendel1';

function SolveBendel1Page() {
  return (
    <div>
      <h1>Solve Bendel 1</h1>
      <SolveBendel1 />
    </div>
  );
}

export default SolveBendel1Page;