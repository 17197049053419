// ./nodes/SubsystemNode.js
import React from 'react';
import { Handle } from 'react-flow-renderer';

const SubsystemNode = ({ data, isConnectable, style }) => (
  <div
    style={{
      ...style,
      position: 'relative',
      padding: '10px',
      background: '#f0f0f0',
      borderRadius: '5px',
      border: '1px solid #777',
    }}
  >
    {/* Input Handle */}
    <Handle
      type="target"
      position="right"
      isConnectable={isConnectable}
      style={{ top: '50%', transform: 'translateY(-50%)', borderRadius: 0 }}
    />

    {/* Output Handle */}
    <Handle
      type="source"
      position="left"
      isConnectable={isConnectable}
      style={{ top: '50%', transform: 'translateY(-50%)', borderRadius: 0 }}
    />

    {/* Node Content */}
    <div><strong>{data.label}</strong></div>

    {/* Display Upstream and Downstream Nodes */}
    <div style={{ fontSize: '12px', marginTop: '5px' }}>
      <strong>Upstream:</strong> {data.upstream?.join(', ') || 'None'}
      <br />
      <strong>Downstream:</strong> {data.downstream?.join(', ') || 'None'}
    </div>
  </div>
);

export default SubsystemNode;