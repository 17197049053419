import React from 'react';
import RegisterForm from '../components/forms/RegisterForm';

function RegisterPage() {
  return (
    <div>
      <h1>Register</h1>
      <RegisterForm />
    </div>
  );
}

export default RegisterPage;