// src/contexts/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_FLASK_URL;

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
   const [environment, setEnvironment] = useState(null);
   const [transportResult, setTransportResult] = useState(null); 

  // Load user from localStorage on app load
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    if (storedUser && storedToken) {
      const parsedUser = JSON.parse(storedUser);
      console.log('Loaded user from localStorage:', parsedUser);
      // Set axios default header
      axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      setUser(parsedUser);
    }
  }, []);

  // Update localStorage whenever the user state changes
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user)); // Store user in localStorage
      localStorage.setItem('token', user.token); // Store token separately
    } else {
      localStorage.removeItem('user'); // Clear localStorage on logout
      localStorage.removeItem('token');
    }
  }, [user]);

  // Function to log out the user
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user'); // Clear user from localStorage
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization']; // Remove auth header
  };

  // Handle login to ensure user id and token are stored properly
  const handleLogin = async (loginData) => {
    try {
      const response = await axios.post(`${backendUrl}/login`, loginData);
      console.log('Login details:', response.data);
      const { token, user: userData } = response.data;
      console.log('token', token);

      // Correctly set user state by spreading userData
      const userWithToken = { ...userData, token };
      setUser(userWithToken);

      console.log('User after setUser:', userWithToken);
    } catch (error) {
      console.error('Login failed', error);
      throw error; // Rethrow the error so that LoginForm can handle it
    }
  };

  return (
    <UserContext.Provider value={{
         user, 
         setUser, 
         environment,
         setEnvironment,
         transportResult,
         setTransportResult,
         logout, 
         handleLogin }
         }>
      {children}
    </UserContext.Provider>
  );
};