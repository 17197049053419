// src/components/PublicRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PublicRoute = ({ children }) => {
    const { user } = useContext(UserContext);

    if (user) {
        // If user is authenticated, redirect them to the home page or any other page
        return <Navigate to="/" />;
    }

    // If user is not authenticated, render the children components (e.g., LoginForm)
    return children;
};

export default PublicRoute;